
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = [
      {
        client: {
          Name: "50",
        },
        order: {
          Number: "1234",
          Itemnumber: "100",
          Date: "100",
        },
        shipping: {
          Date: "201223456",
        },
      },
      {
        client: {
          Name: "60",
        },
        order: {
          Number: "2234",
          Itemnumber: "101",
          Date: "102",
        },
        shipping: {
          Date: "101223456",
        },
      },
      {
        client: {
          Name: "70",
        },
        order: {
          Number: "91234",
          Itemnumber: "102",
          Date: "99",
        },
        shipping: {
          Date: "301223456",
        },
      },
    ];
    const ban = (evt: any) => {
      evt.target.classList.add("active");
    };
    return {
      list,
      ban,
    };
  },
});
