
import { defineComponent, onMounted } from "vue";
import KTCard from "@/components/cards/Card1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AccountQualityList from "@/ComponentsSystem/Account/AccountQualityList.vue";
import AccountTimeList from "@/ComponentsSystem/Account/AccountTimeList.vue";
import AccountProductList from "@/ComponentsSystem/Account/AccountProductList.vue";
import AccountFixtureList from "@/ComponentsSystem/Account/AccountFixtureList.vue";
import AccountChangeList from "@/ComponentsSystem/Account/AccountChangeList.vue";
import AccountWorkList from "@/ComponentsSystem/Account/AccountWorkList.vue";
import AccountCapacityList from "@/ComponentsSystem/Account/AccountCapacityList.vue";
import AccountElementList from "@/ComponentsSystem/Account/AccountElementList.vue";

export default defineComponent({
  name: "projects",
  components: {
    KTCard,
    AccountQualityList,
    AccountTimeList,
    AccountProductList,
    AccountFixtureList,
    AccountChangeList,
    AccountWorkList,
    AccountCapacityList,
    AccountElementList,
  },
  data(): {
    DataType: string;
    ProductType: string;
    DataNumber: string;
  } {
    return {
      DataType: "",
      ProductType: "",
      DataNumber: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("輸入更新", ["戰情中台"]);
    });

    return {};
  },
});
